import React, { useEffect, useRef } from "react";
import CartItem from "../CartItem/CartItem";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
   Grid,
   makeStyles,
   CircularProgress,
   Typography,
   Button,
   withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { clearCart } from "../../Redux/cartSlice";
import { openSnackBar } from "../../Redux/appSlice";
import { getTotal } from "../../Redux/cartSlice";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
 

const useStyles = makeStyles((theme) => ({
   container: {
      display: "flex",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
   },
   progress: {
      padding: theme.spacing(10),
   },
   priceDiv: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.spacing(2),
      boxShadow: theme.shadows[2],
      overflow: "hidden",
      padding: theme.spacing(3),
   },
   btnContainer: {
      marginTop: theme.spacing(2),
   },
   navigateBtn: {
      marginBottom: theme.spacing(4),
   },
   marginRightTwo: {
      marginRight: theme.spacing(2),
   },
   marginTopTwo: {
      marginTop: theme.spacing(2),
   },
   letterSpace: {
      letterSpacing: 3,
   },
}));

const mapThroughItems = (items) => {
   return items.map((item, idx) => {
      return <CartItem key={idx} item={item} />;
   });
};
const DangerButton = withStyles((theme) => ({
   root: {
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      letterSpacing: 2,
      transition: "0.3s all ease-in-out",
      "&:hover": {
         transform: "ScaleX(1.05)",
         color: theme.palette.common.white,
         borderColor: theme.palette.error.light,
         backgroundColor: theme.palette.error.light,
      },
   },
}))(Button);
const SuccessButton = withStyles((theme) => ({
   root: {
      borderColor: theme.palette.success.main,
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      letterSpacing: 2,
      transition: "0.3s all ease-in-out",
      "&:hover": {
         transform: "ScaleX(1.05)",
         color: theme.palette.common.white,
         borderColor: theme.palette.success.light,
         backgroundColor: theme.palette.success.light,
      },
   },
}))(Button);

const CartList = () => {
   const { cartItems, pending, cartLength, error, total } = useSelector(
      (state) => state.cart
   );
   const classes = useStyles();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const isMounted = useRef(false);


  const [lowPriceDialog, setLowPriceDialog] = React.useState(false);

  const handleLowPriceDialogOpen = () => {
   setLowPriceDialog(true);
  };

  const handleLowPriceClose = (reason) => {
   setLowPriceDialog(false);
  };

   useEffect(() => {
      if (isMounted.current && cartLength !== 0) {
         dispatch(getTotal());
      } else {
         isMounted.current = true;
      }
   }, [dispatch, cartItems, cartLength]);

   const handleClearCart = () => {
      dispatch(clearCart());
      if (!error && !pending) {
         dispatch(
            openSnackBar({
               severity: "success",
               text: "Cart has been cleared",
            })
         );
      } else if (error && !pending) {
         dispatch(
            openSnackBar({
               severity: "error",
               text: "Something went wrong",
            })
         );
      }
   };

   return (
      <>
         {!pending && cartLength !== 0 ? (
            <Grid className={classes.container} container item xs={10} lg={8}>
               <Button
                  onClick={() => navigate("/products")}
                  variant="contained"
                  color="primary"
                  className={classNames(
                     classes.letterSpace,
                     classes.navigateBtn
                  )}
               >
                  Continue Shopping
               </Button>
               {mapThroughItems(cartItems)}
               <Grid
                  item
                  container
                  justifyContent="flex-end"
                  xs={12}
                  className={classes.priceDiv}
               >
                  <Typography variant="body1" color="initial">
                     Total: {total}
                  </Typography>
               </Grid>
               <Grid
                  item
                  container
                  justifyContent="flex-end"
                  className={classes.btnContainer}
               >
                  <DangerButton
                     variant="contained"
                     onClick={handleClearCart}
                     className={classes.marginRightTwo}
                  >
                     Clear Cart
                  </DangerButton>

                 {total > 500 ?  <a href="/checkout" id="instaLink"  >
                  <SuccessButton
                     variant="contained"
                     onClick={() => {
                        if(total < 500){
                           dispatch(
                              openSnackBar({
                                 severity: "error",
                                 text: "Sorry for inconvenience, please order minimum of INR 500. Contact us incase of any queries.",
                              })
                           );
                        }else{
                         //  navigate("/checkout")
                        }
                     }}
                  >
                     Checkout
                  </SuccessButton>
                  </a>
                     :
                  
                  <SuccessButton
                     variant="contained"
                     onClick={() => {
                        if(total < 500){
                           dispatch(
                              openSnackBar({
                                 severity: "error",
                                 text: "Sorry for inconvenience, please order minimum of INR 500. Contact us incase of any queries.",
                              })
                           );
                        }
                     }}
                  >
                     Checkout
                  </SuccessButton>
                  }
               </Grid>
            </Grid>
         ) : !pending && cartLength === 0 ? (
            <Grid
               className={classes.container}
               direction="column"
               alignItems="center"
               container
               item
            >
               <Typography variant="h4" color="initial">
                  Cart is empty
               </Typography>
               <Button
                  color="primary"
                  onClick={() => navigate("/products")}
                  variant="contained"
                  className={classNames(
                     classes.letterSpace,
                     classes.marginTopTwo
                  )}
               >
                  Shop Now!!!
               </Button>
            </Grid>
         ) : (
            <Grid
               className={classNames(classes.progress, classes.container)}
               container
            >
               <CircularProgress size="5rem" />
            </Grid>
         )}

{/* <Dialog
        cancel
        open={lowPriceDialog}
        onClose={handleLowPriceClose}
        PaperProps={{
          style: {
            minHeight: '30%',
            minWidth: "70%"
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1">
            {
              "Oops, please place order of minimum INR 500"
            }
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body2">
               Sorry for inconvenience, please order minimum of INR 500. Contact us incase of any quiries.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLowPriceClose} autoFocus>
            <Typography variant="body1">{"Okay"}</Typography>
          </Button>

          <Button onClick={handleLowPriceClose} autoFocus>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B918007219428&text=Hi%20There!%20I%20came%20across%20your%20products%20from%20VanillanSaffron%20&%20I%20have%20a%20query.&type=phone_number&app_absent=0"
              id="instaLink"
              target="_blank"
            >
              <Typography variant="body1">{"Contact Now"}</Typography>
            </a>
          </Button>
        </DialogActions>
      </Dialog> */}

      </>
   );
};

export default CartList;
