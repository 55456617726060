import React from "react";
import {
   Footer,
   Header,
   PrivateRoute,
   CustomSnackbar,
   Redirect,
} from "./Components";
import {
   Account,
   Cart,
   Delivery,
   Checkout,
   Home,
   Login,
   Products,

   ContactUs,
   SignUp,
   SingleProductPage,
   Shipping,
   Error,
   OrderHistory,
   Payments,
   ResetPassword,
   ChangePassword,
   OrderConfirmation,
   AppFailure,
   PrivacyPolicy,
   RefundsAndCancellations,
   ShippingPolicy,
   TermsandConditions
} from "./Pages";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics , logEvent} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyBoOcHIrEIRQkHw8tBTkhpm6Hb7w5ZL1og",
  authDomain: "vanillansaffron-develop.firebaseapp.com",
  projectId: "vanillansaffron-develop",
  storageBucket: "vanillansaffron-develop.appspot.com",
  messagingSenderId: "837887480327",
  appId: "1:837887480327:web:c671e99def7681ffc690a2",
  measurementId: "G-X2YJHFYBBY"
 };

const App = () => {
   const { url } = useSelector((state) => state.cart);
   // Initialize Firebase
   const app = initializeApp(firebaseConfig);
   const analytics = getAnalytics(app);
   logEvent(analytics, 'App is Live!');
   return (
      <>
         <CustomSnackbar />
         <Routes>
            <Route
               path="/"
               element={
                  <>
                     <Header />
                     <Home />
                  </>
               }
            />
            <Route
               path="/products"
               element={
                  <>
                     <Header />
                     <Products />
                     <Footer />
                  </>
               }
            />
             <Route
               path="/privacypolicy"
               element={
                  <>
                     <Header />
                     <PrivacyPolicy />
                     <Footer />
                  </>
               }
            />
            <Route
               path="/refundsAndCancellations"
               element={
                  <>
                     <Header />
                     <RefundsAndCancellations />
                     <Footer />
                  </>
               }
            />  
            <Route
               path="/shippingPolicy"
               element={
                  <>
                     <Header />
                     <ShippingPolicy />
                     <Footer />
                  </>
               }
            />
             <Route
               path="/termsAndConditions"
               element={
                  <>
                     <Header />
                     <TermsandConditions />
                     <Footer />
                  </>
               }
            />
            <Route
               path="/aboutus"
               element={
                  <>
                     <Header />
                     <ContactUs />
                     {/* <Footer /> */}
                  </>
               }
            />
            <Route
               path="/account"
               element={
                  <PrivateRoute>
                     <Header />
                     <Account />
                     <Footer />
                  </PrivateRoute>
               }
            />
            <Route
               path="/cart"
               element={
                  <PrivateRoute>
                     <Header />
                     <Cart />
                     <Footer />
                  </PrivateRoute>
               }
            />

            <Route
               path="/orderHistory"
               element={
                  <PrivateRoute>
                     <Header />
                     <OrderHistory />
                     <Footer />
                  </PrivateRoute>
               }
            />

            <Route
               path="/delivery"
               element={
                  <PrivateRoute>
                     <Header />
                     <Delivery />
                     <Footer />
                  </PrivateRoute>
               }
            />

            {url === "" ? (
               <Route
                  path="/checkout"
                  element={
                     <PrivateRoute>
                        <Header />
                        <Checkout />
                        <Footer />
                     </PrivateRoute>
                  }
               />
            ) : (
               <Route path="/checkout" element={<Redirect url={url} />} />
            )}

            <Route
               path="/products/:id"
               element={
                  <>
                     <Header />
                     <SingleProductPage />
                     <Footer />
                  </>
               }
            />

            <Route
               path="/order-confirmed/:id"
               element={
                  <>
                     <Header />
                     <OrderConfirmation />
                     <Footer />
                  </>
               }
            />
            
            <Route
               path="/failure"
               element={
                  <>
                     <Header />
                     <AppFailure />
                     <Footer />
                  </>
               }
            />

            <Route
               path="/payments/:id"
               element={
                  <>
                     <Header />
                     <Payments />
                     <Footer />
                  </>
               }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/changepassword/:authcode" element={<ChangePassword />} />
            <Route path="/register" element={<SignUp />} />
            <Route
               path="*"
               element={
                  <>
                     <Header />
                     <Error />
                  </>
               }
            />
            <Route
               path="/shipping"
               element={
                  <PrivateRoute>
                     <Header />
                     <Shipping />
                     <Footer />
                  </PrivateRoute>
               }
            />
         </Routes>
      </>
   );
};

export default App;
